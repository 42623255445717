<template>
  <div class="statistical">
    <div class="statistical_top">
      <div class="statistical_flex">
        <div class="statistical_top_flex">
          <div class="top_name">
            <span>消息内容概览</span>
          </div>
          <div class="load_time">
            <img class="loading_img" src="../../assets/image//loading.png" alt="" />
            <span>最近群发更新时间：{{ headinfo.updateTime }}</span>
          </div>
        </div>
        <div class="flex_right">
          <div class="flex_right_time">
            <div
              @click="tabitem(index)"
              :class="tabindex == index ? 'right_item' : 'right_back'"
              v-for="(item, index) in tabList"
              :key="index"
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="back_color" v-show="tabindex == 2">
            <div class="po_time">
              <i class="el-icon-date"></i>
            </div>
            <el-date-picker
              v-model="form.date"
              type="date"
              @change="statistics"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <div class="back_color" v-show="tabindex == 1">
            <div class="po_time">
              <i class="el-icon-date"></i>
            </div>
            <el-date-picker
              value-format="yyyy-MM"
              v-model="form.date"
              @change="statistics"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
          </div>
          <div class="back_color" v-show="tabindex == 0">
            <div class="po_time">
              <i class="el-icon-date"></i>
            </div>
            <el-date-picker
              value-format="yyyy"
              v-model="form.date"
              @change="statistics"
              type="year"
              placeholder="选择年"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="flex_top">
        <div class="statistical_top_center">
          <div class="po_progress_flex">
            <el-progress
              type="circle"
              :stroke-width="10"
              :show-text="false"
              color="#448aff"
              :percentage="100"
            >
            </el-progress>
            <div class="po_progress">
              <div>
                <span>消息内容数</span>
              </div>
              <div class="num_size">
                <span>{{ headinfo.messageCnt }}</span>
              </div>
            </div>
          </div>
          <div class="left_one">
            <div class="top_txt">
              <img src="../../assets/image/send1.png" alt="" />
              <span class="txt_color">已发送（条）</span>
            </div>
            <div class="down_txt">
              <span>{{ headinfo.groupCntSend }}</span>
            </div>
          </div>
          <div class="left_one">
            <div class="top_txt">
              <img src="../../assets/image/send2.png" alt="" />
              <span class="txt_color">未发送（条）</span>
            </div>
            <div class="down_txt">
              <span>{{ headinfo.noSendMessageCnt }}</span>
            </div>
          </div>
          <div class="left_one">
            <div class="top_txt">
              <img src="../../assets/image/send3.png" alt="" />
              <span class="txt_color">已发送群聊数（个）</span>
            </div>
            <div class="down_txt">
              <span>{{ headinfo.groupCntSend }}</span>
            </div>
          </div>
          <div class="left_one">
            <div class="top_txt">
              <img src="../../assets/image/send4.png" alt="" />
              <span class="txt_color">未发送群聊数（个）</span>
            </div>
            <div class="down_txt">
              <span>{{ headinfo.groupCntNoSend }}</span>
            </div>
          </div>
        </div>
        <div class="border_left"></div>
        <div class="statistical_top_center">
          <div class="right_two">
            <div class="right_two_top">
              <span>{{ topInfo.time }}</span>
              <span>已发送内容数（条）</span>
              <span class="txt_color">{{ topInfo.sendMessageCnt }}</span>
            </div>
            <div class="right_two_dwon">
              <span>{{ topInfo.time }}已发送群数（个）</span>
              <!-- <span>已发送群数（个）</span> -->
              <span class="txt_color">{{ topInfo.groupCntSend }}</span>
            </div>
          </div>
          <div class="right_two">
            <div class="right_two_top">
              <span>{{ topInfo.time }}</span>
              <span>未发送内容数（条）</span>
              <span class="txt_color">{{ topInfo.noSendMessageCnt }}</span>
            </div>
            <div class="right_two_dwon">
              <span>{{ topInfo.time }}未发送群数（个）</span>
              <!-- <span>未发送群数（个）</span> -->
              <span class="txt_color">{{ topInfo.groupCntNoSend }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistical_center">
      <el-form ref="form" :model="queryInfo" label-width="100px">
        <div class="flex_type">
          <el-form-item label="消息类别：">
            <div class="radio_group">
              <el-radio-group v-model="queryInfo.type">
                <el-radio-button label="">全部</el-radio-button>
                <el-radio-button
                  v-for="(item, index) in questList"
                  :key="index"
                  :label="item.name"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="消息类型：">
            <el-checkbox-group v-model="contentTypes" @change="unitChange">
              <el-checkbox-button label="">全部</el-checkbox-button>
              <el-checkbox-button
                v-for="(item, index) in datalist"
                :key="index"
                :label="item.name"
                >{{ item.name }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
        <el-form-item label="发送日期：">
          <el-date-picker
            v-model="valueTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="valtime"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="center_sreach">
      <div class="flex_btn">
        <el-button type="primary" @click="addmass">新增消息</el-button>
        <div class="load_txt" @click="dxexport">
          <img class="icon_size" src="../../assets/image/daochu.png" alt="" />
          导出数据分析
        </div>
      </div>
      <div class="search_right">
        <el-input
          placeholder="请输入内容"
          v-model="queryInfo.keyword"
          class="input-with-select"
          clearable
        >
          <el-button slot="append" @click="tabSearch" type="primary">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="row">
      <div class="row_txt">
        <span>当前条件下共查出</span>
        <span class="color1">{{ total }}</span>
        <span>条消息</span>
      </div>
      <div class="sort">
        <span @click="handleSort(sortVal)">按总发送群</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSorts(sortVals)">按已发送群</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActives === 'up' ? 'top_active' : ''"
            @click="handleSorts('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActives === 'down' ? 'bottom_active' : ''"
            @click="handleSorts('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSortss(sortValss)">按未发送群</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActivess === 'up' ? 'top_active' : ''"
            @click="handleSortss('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActivess === 'down' ? 'bottom_active' : ''"
            @click="handleSortss('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSortfour(sortValfour)">按群查看</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActivefour === 'up' ? 'top_active' : ''"
            @click="handleSortfour('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActivefour === 'down' ? 'bottom_active' : ''"
            @click="handleSortfour('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSortfive(sortValfive)">按用户查看</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActivefive === 'up' ? 'top_active' : ''"
            @click="handleSortfive('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActivefive === 'down' ? 'bottom_active' : ''"
            @click="handleSortfive('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSortsix(sortValsix)">按未查看群</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActivesix === 'up' ? 'top_active' : ''"
            @click="handleSortsix('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActivesix === 'down' ? 'bottom_active' : ''"
            @click="handleSortsix('down')"
          ></div>
        </div>
      </div>
      <div class="sort">
        <span @click="handleSorteve(sortValeve)">按群查看率</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActiveeve === 'up' ? 'top_active' : ''"
            @click="handleSorteve('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActiveeve === 'down' ? 'bottom_active' : ''"
            @click="handleSorteve('down')"
          ></div>
        </div>
      </div>
    </div>
    <div class="statistical_bottom">
      <tabitem
        ref="childRef"
        :info="queryInfo"
        @totalClick="totalClick"
        @dialogClick="dialogClick"
        @checkClick="checkClick"
      ></tabitem>
    </div>
    <el-dialog
      :visible.sync="addDialogVisible"
      :close-on-click-modal="false"
      top="100px"
      width="60%"
    >
      <template slot="title">
        <div class="custom_title">
          <div class="border_left"></div>
          <span>用户查看{{ userTotal || '' }}</span>
        </div>
      </template>
      <el-table
        :data="userList"
        style="width: 100%"
        @sort-change="sortChange"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="头像" align="center" prop="id">
          <template v-slot="{ row }">
            <img class="crile_size" v-if="!row.head" src="../../assets/image/head9.png" alt="" />
            <img class="crile_size" v-else :src="row.head" alt="" />
          </template>
        </el-table-column>

        <el-table-column label="昵称" prop="nick" align="center">
          <template v-slot="{ row }">
            <div>{{ row.nick }}</div>
          </template>
        </el-table-column>
        <el-table-column label="性别" prop="sex" align="center">
          <template v-slot="{ row }">
            <div v-if="row.sex">男</div>
            <div v-else>女</div>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="phone" align="center">
          <template v-slot="{ row }">
            <div>{{ row.phone }}</div>
          </template>
        </el-table-column>
        <el-table-column label="所在城市" prop="city" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}</span>
            <span v-if="row.city">{{ row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column label="查看次数" sortable prop="viewCnt" align="center">
          <template v-slot="{ row }">
            <div>{{ row.viewCnt }}</div>
          </template>
        </el-table-column>
        <el-table-column label="查看时间" sortable prop="viewTime" align="center">
          <template v-slot="{ row }">
            <div>{{ row.viewTime }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="userTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
    <el-dialog :visible.sync="DialogVisible" :close-on-click-modal="false" top="100px" width="50%">
      <template slot="title">
        <div class="custom_title">
          <div class="border_left"></div>
          <span>{{ activeName }}</span>
        </div>
      </template>
      <el-table
        :data="groupList"
        @sort-change="sortChange"
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <!-- <el-table-column v-if="activeType != 1" label="编号" align="center" prop="id">
          <template v-slot="{ row }">
            <div>{{ row.id }}</div>
          </template>
        </el-table-column> -->
        <el-table-column label="群聊名称" align="center" prop="wechatGroupName">
          <template v-slot="{ row }">
            <div>{{ row.wechatGroupName }}</div>
          </template>
        </el-table-column>

        <el-table-column label="成员数量" sortable prop="memCnt" align="center">
          <template v-slot="{ row }">
            <div>{{ row.memCnt }}</div>
          </template>
        </el-table-column>

        <el-table-column
          v-if="activeType == 1"
          sortable
          label="发送时间"
          prop="sendTime"
          align="center"
        >
          <template v-slot="{ row }">
            <span>{{ row.sendTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="activeType == 3"
          sortable
          label="查看时间"
          prop="viewTime"
          align="center"
        >
          <template v-slot="{ row }">
            <span>{{ row.viewTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryDia.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryDia.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
  </div>
</template>
    
    <script>
import { statistics, dxexport, userview, wechatGroupList } from '@/api/masssend.js'
import tabitem from './components/tabitem.vue'
import dialogtable from './components/dialogtable.vue'
import dialogCheck from './components/dialogCheck.vue'
const defaultQueryInfo = Object.freeze({
  contentTypes: [],
  orderByParam: 0,
  pageNum: 1,
  pageSize: 10,
  sendEndTime: '',
  sendStartTime: '',
  keyword: '',
  type: ''
})
const defaultDialogInfo = Object.freeze({
  dsMsgId: '',
  orderByParam: 1,
  pageNum: 1,
  pageSize: 10,
  type: 0
})
export default {
  name: 'statistical',
  components: { tabitem, dialogtable, dialogCheck },
  data() {
    return {
      value1: '',
      tabindex: 1,
      activeName: '',
      activeType: 0,
      activeId: '',
      activeIds: '',
      form: {
        date: '',
        type: 1
      },
      valueTime: [],
      isActive: '', // 排序图标样式
      questionnaireName: '', //导出名
      ids: [],
      groupList: [{}],
      userList: [],
      userTotal: 0,
      total: 0,
      addDialogVisible: false,
      DialogVisible: false,
      questList: [
        {
          name: '业务内容'
        },
        {
          name: '节日祝福'
        },
        {
          name: '宣传物料'
        },
        {
          name: '其他'
        }
      ], //工具list

      queryInfo: { ...defaultQueryInfo },
      queryDia: { ...defaultDialogInfo },

      tabList: [
        {
          name: '年',
          value: 0
        },
        {
          name: '月',
          value: 1
        },
        {
          name: '日',
          value: 2
        }
      ],
      loading: false,
      headinfo: {}, //头部数据
      topInfo: {},
      datalist: [
        {
          name: '文本'
        },
        {
          name: '照片'
        },
        {
          name: '视频'
        },

        {
          name: '附件'
        }
      ],
      sortIndex: 0,
      contentTypes: [''],
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      sortVals: 'up', // 排序点击事件的传参
      isActives: '', // 排序图标样式
      sortValss: 'up', // 排序点击事件的传参
      isActivess: '', // 排序图标样式
      sortValfour: 'up', // 排序点击事件的传参
      isActivefour: '', // 排序图标样式
      sortValfive: 'up', // 排序点击事件的传参
      isActivefive: '', // 排序图标样式
      sortValsix: 'up', // 排序点击事件的传参
      isActivesix: '', // 排序图标样式
      sortValeve: 'up', // 排序点击事件的传参
      isActiveeve: '' // 排序图标样式
    }
  },
  computed: {},
  created() {
    this.form.date = this.getCurrentDate(1)

    this.statistTop()
  },
  mounted() {},
  destroyed() {},
  methods: {
    async statistics() {
      const res = await statistics(this.form)
      if (res.resultCode == 200) {
        this.topInfo = res.data
        if (this.form.type == 0) {
          this.topInfo.time = this.form.date
          this.topInfo.time = this.topInfo.time + '年'
        } else if (this.form.type == 1) {
          this.topInfo.time = this.form.date.replace(/^[^-]*-/, '')
          this.topInfo.time = this.topInfo.time.replace('0', '')
          this.topInfo.time = this.topInfo.time + '月'
        } else {
          this.topInfo.time = this.form.date.replace(/^[^-]*-/, '')
        }
      }
    },
    async statistTop() {
      const res = await statistics()
      if (res.resultCode == 200) {
        this.headinfo = res.data
        this.statistics()
      }
    },
    async dxexport() {
      this.loading = true
      let ids = this.$refs.childRef.ids

      const query = { ...this.queryInfo, ids }
      delete query.pageNum
      delete query.pageSize
      const res = await dxexport(query)
      if (res) {
        this.loading = false
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date

        a.download = `${nowtime} 群发数据.xlsx` //指定下载文件名
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.loading = false
        this.$message.warning('下载失败!')
      }
    },
    getCurrentDate(index) {
      const now = new Date()
      const year = now.getFullYear()
      const month = String(now.getMonth() + 1).padStart(2, '0')
      const day = String(now.getDate()).padStart(2, '0')
      if (index == 0) {
        return `${year}`
      } else if (index == 1) {
        return `${year}-${month}`
      } else {
        return `${year}-${month}-${day}`
      }
    },

    addmass() {
      this.$router.push({
        name: 'CreateContent'
      })
    },

    changVal(val) {
      this.questList.forEach((el) => {
        console.log(el)

        if (val == el.id) {
          this.questionnaireName = el.questionnaireName
        }
      })
    },

    unitChange(val) {
      console.log(val)
      this.queryInfo.contentTypes = []
      val.forEach((el, i) => {
        if (el) {
          this.queryInfo.contentTypes.push(el)
        }
      })
    },
    tabitem(index) {
      this.tabindex = index
      this.form.type = index
      this.form.date = this.getCurrentDate(index)

      this.statistics()
    },
    valtime(val) {
      if (val) {
        this.queryInfo.sendStartTime = val[0]
        this.queryInfo.sendEndTime = val[1]
      } else {
        this.queryInfo.sendStartTime = ''
        this.queryInfo.sendEndTime = ''
      }

      this.$refs.childRef.search()
    },

    //排序
    handleSort(val) {
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortVal = 'down'
        this.isActive = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortVal = 'up'
        this.isActive = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortVal = 'up'
        this.isActive =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    //排序
    handleSorts(val) {
      
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortVals = 'down'
        this.isActives = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortVals = 'up'
        this.isActives = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortVals = 'up'
        this.isActives =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    //排序
    handleSortss(val) {
     
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortValss = 'down'
        this.isActivess = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortValss = 'up'
        this.isActivess = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortValss = 'up'
        this.isActivess =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    //排序
    handleSortfour(val) {
     
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortValfour = 'down'
        this.isActivefour = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortValfour = 'up'
        this.isActivefour = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortValfour = 'up'
        this.isActivefour =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    //排序
    handleSortfive(val) {
      
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortValfive = 'down'
        this.isActivefive = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortValfive = 'up'
        this.isActivefive = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortValfive = 'up'
        this.isActivefive =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    //排序
    handleSortsix(val) {
    
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortValsix = 'down'
        this.isActivesix = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortValsix = 'up'
        this.isActivesix = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortValsix = 'up'
        this.isActivesix =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    //排序
    handleSorteve(val) {
      
      this.sortIndex++
      
      if (val === 'up' && this.sortIndex == 1) {
        this.queryInfo.orderByParam = 13
        this.sortValeve = 'down'
        this.isActiveeve = val
        this.$refs.childRef.search()
      } else if (val === 'down' && this.sortIndex === 2) {
        this.queryInfo.orderByParam = 14
        this.sortValeve = 'up'
        this.isActiveeve = val
        this.$refs.childRef.search()
      } else {
        this.queryInfo.orderByParam = 0
        this.sortValeve = 'up'
        this.isActiveeve =''
        this.sortIndex = 0
        this.$refs.childRef.search()
      }
    },
    dialogClick(type, val, id) {
      this.activeName = val
      this.activeType = type
      this.queryDia.dsMsgId = id
      this.queryDia.orderByParam = 1
      this.queryDia.type = this.activeType
      this.DialogVisible = true
      this.wechatGroupList()
    },
    totalClick(val) {
      this.total = val
    },
    checkClick(val) {
      this.queryDia.dsMsgId = val
      this.queryDia.orderByParam = 1
      this.addDialogVisible = true
      this.userview()
    },
    async wechatGroupList() {
      const res = await wechatGroupList(this.queryDia)
      if (res.resultCode == 200) {
        this.groupList = res.data.list

        this.total = res.data.total
      }
    },
    async userview() {
      delete this.queryDia.type
      const res = await userview(this.queryDia)
      if (res.resultCode == 200) {
        this.userList = res.data.list
        this.userTotal = res.data.total
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryDia.pageSize = val
      if (this.addDialogVisible) {
        this.userview()
      } else {
        this.wechatGroupList()
      }
    },
    // 排序
    sortChange(val) {
      if (val.prop === 'sendTime') {
        if (val.order === 'ascending') {
          this.queryDia.orderByParam = 3
        } else if (val.order === 'descending') {
          this.queryDia.orderByParam = 2
        } else {
          this.queryDia.orderByParam = 2
        }
      } else if (val.prop === 'memCnt') {
        if (val.order === 'ascending') {
          this.queryDia.orderByParam = 0
        } else if (val.order === 'descending') {
          this.queryDia.orderByParam = 1
        } else {
          this.queryDia.orderByParam = 1
        }
      } else if (val.prop === 'viewCnt') {
        if (val.order === 'ascending') {
          this.queryDia.orderByParam = 0
        } else if (val.order === 'descending') {
          this.queryDia.orderByParam = 1
        } else {
          this.queryDia.orderByParam = 1
        }
      }

      if (this.addDialogVisible) {
        if (val.prop === 'viewTime') {
          if (val.order === 'ascending') {
            this.queryDia.orderByParam = 3
          } else if (val.order === 'descending') {
            this.queryDia.orderByParam = 2
          } else {
            this.queryDia.orderByParam = 2
          }
        }
        this.userview()
      } else {
        this.wechatGroupList()
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.queryDia.pageNum = val
      if (this.addDialogVisible) {
        this.userview()
      } else {
        this.wechatGroupList()
      }
    },
    //搜索
    tabSearch() {
      this.$refs.childRef.search()
    }
  }
}
</script>
    
    <style lang="less" scoped>
::v-deep .el-form-item {
  margin-bottom: 5px;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-checkbox-button .el-checkbox-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-checkbox-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-input__prefix {
  position: absolute;
  height: 100%;
  left: 0.3125rem;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 1.875rem;
  border: 1px solid #fff;
  background: #fbfbfb;
}
::v-deep .el-input__suffix {
  position: absolute;
  height: 100%;
  right: 0.3125rem;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
  display: none;
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.custom_title {
  position: relative;
  font-size: 16px;
  .border_left {
    position: absolute;
    height: 16px;
    width: 4px;
    background: #448aff;
    left: -20px;
  }
}
.statistical {
  width: 100%;
  height: calc(100vh - 100px);
  .statistical_top {
    width: 100%;
    background: #fff;
    padding: 10px 0px;
    box-shadow: 0px 4px 4px 0px #c1c1c126;
    border-radius: 4px;
    .back_color {
      position: relative;
      .po_time {
        position: absolute;
        color: #448aff;
        font-size: 16px;
        left: 10px;
        top: 10px;
        z-index: 99999 !important;
      }
    }
    .statistical_flex {
      display: flex;
      justify-content: space-between;
      .flex_right {
        display: flex;
        align-items: center;
        padding-right: 20px;
        .flex_right_time {
          display: flex;
          align-items: center;
          width: 96px;
          height: 38px;
          background: #fbfbfb;
          font-size: 12px;
          margin-right: 16px;

          .right_item {
            width: 27px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 4px;
            background: #85b6ff;
            color: #fff;
            cursor: pointer;
          }
          .right_back {
            width: 27px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 4px;
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
    }
    .statistical_top_flex {
      display: flex;
      align-items: center;
      .load_time {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
      .loading_img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      .top_name {
        font-size: 16px;
        font-weight: bold;
        margin: 0px 15px;
      }
      .tab_flex {
        display: flex;
        align-items: center;
        border-radius: 2px;
        margin-right: 15px;
        .tab_flex_item {
          height: 37px;
          border-radius: 1px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          border: 1px solid #d7dbec;
          cursor: pointer;
        }
        .back {
          color: #448aff;
          border: 1px solid #448aff;
        }
      }
    }
    .flex_top {
      display: flex;
      align-items: center;
      .border_left {
        height: 40px;
        background: #c4c4c4;
        width: 2px;
        margin-top: 10px;
      }
    }
    .statistical_top_center {
      display: flex;
      align-items: center;
      margin-top: 5px;
      padding: 0px 20px;
      .po_progress_flex {
        position: relative;
        .po_progress {
          position: absolute;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          left: 32px;
          top: 40px;
        }
        .num_size {
          font-size: 20px;
          margin-top: 10px;
        }
      }
      .left_one {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 0px 40px;
        .top_txt {
          display: flex;
          align-items: center;

          font-size: 16px;
          .txt_color {
            margin-left: 5px;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        .down_txt {
          font-size: 24px;
          margin-top: 30px;
        }
      }
      .right_two {
        color: #9b9a9a;
        margin-left: 30px;
        font-size: 16px;
        .right_two_top {
          min-width: 200px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          .txt_color {
            color: #333333;
            margin-left: 10px;
          }
        }
        .right_two_dwon {
          min-width: 200px;
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .txt_color {
            margin-left: 10px;
            color: #333333;
          }
        }
      }
    }
  }
  .statistical_center {
    padding: 20px;
    background: #fff;
    margin-top: 15px;
    box-shadow: 0px 4px 4px 0px #c1c1c126;
    border-radius: 4px;
    .flex_type {
      display: flex;
      align-items: center;
    }
  }
  .row {
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
    top: 5px;
  }
  .row_txt {
    color: #393939;
    font-size: 16px;
  }
  .sort {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #595959;
    span {
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      margin-right: 5px;
    }
    .sort_icon {
      cursor: pointer;
      display: flex;
      flex-flow: column;
      height: auto;
      i {
        width: 14px;
        height: 14px;
        color: #dbd6d6;
        font-size: 14px;
      }
      .isActive {
        color: #8a8888 !important;
      }
    }
  }
  .center_sreach {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 20px;
    margin-top: 16px;
    .flex_btn {
      display: flex;
      align-items: center;
    }
    .load_txt {
      width: 138px;
      height: 40px;
      border: 1px solid #c4c4c4;
      border-radius: 4px;
      margin-left: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      .icon_size {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }

    .search_right {
      width: 460px;
      margin-right: 20px;
      /deep/ .el-button {
        border: 1px solid #448aff;
        color: #fff;
        border-radius: 0;
        background-color: #448aff;
      }
    }
  }
  .crile_size {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  .statistical_bottom {
    box-shadow: 0px 4px 4px 0px #c1c1c126;
    min-height: calc(100vh - 660px);
    border-radius: 4px;

    .statistical_top_flex {
      display: flex;
      align-items: center;
      .buttom_left {
        margin-left: 15px;
      }

      .top_name {
        font-size: 16px;
        font-weight: bold;
        margin: 0px 15px;
      }
      .tab_flex {
        display: flex;
        align-items: center;
        border-radius: 2px;
        margin-right: 15px;
        .tab_flex_item {
          height: 37px;
          border-radius: 1px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          border: 1px solid #d7dbec;
          cursor: pointer;
        }
        .back {
          color: #448aff;
          border: 1px solid #448aff;
        }
      }
      .line_right {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .line_right_item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          img {
            height: 30px;
            width: 20px;
            margin-right: 10px;
          }
        }
      }
    }
    .icon_flex {
      display: flex;
      align-items: center;
      margin: 0px 30px;
      .icon_flex_item {
        color: #448aff;
        border: 1px solid #448aff;
        padding: 0px 10px;
        height: 37px;

        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .icon_back {
        background: #448aff;
        color: #fff;
      }
    }
  }
}
</style>
    