<template>
  <div
    class="customer_manager"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="card_list">
      <div class="card_item" v-for="(item, index) in list" :key="index">
        <div class="card_left">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>

          <div class="img_box" @click="toEnterpriseDetails(item)">
            <div class="po_left bg1" v-if="item.type == '宣传物料'">
              <span>{{ item.type }}</span>
            </div>
            <div class="po_left bg2" v-if="item.type == '业务内容'">
              <span>{{ item.type }}</span>
            </div>
            <div class="po_left bg3" v-if="item.type == '节日祝福'">
              <span>{{ item.type }}</span>
            </div>
            <div class="po_left bg4" v-if="item.type == '其他'">
              <span>{{ item.type }}</span>
            </div>
            <div class="videoUrl" v-if="item.videoUrl">
              <img src="../../../assets/image/videoicon.png" alt="" />
            </div>
            <div v-if="item.type == '业务内容'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <!-- <img v-else-if="item.videoUrl" :src="item.videoUrl" alt=""> -->
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf1.png" alt="" />
            </div>
            <div v-if="item.type == '节日祝福'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf2.png" alt="" />
            </div>
            <div v-if="item.type == '其他'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf3.png" alt="" />
            </div>
            <div v-if="item.type == '宣传物料'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf4.png" alt="" />
            </div>
          </div>
          <div class="info_box">
            <div class="row_flex">
              <div class="enterprise_name" @click="toEnterpriseDetails(item)">
                {{ item.title }}
              </div>
            </div>

            <div class="row_margin">
              <span class="clueType_margin">消息类型：</span>

              <img
                v-if="item.ischeck"
                class="icon_img"
                src="../../../assets/image/txt1.png"
                alt=""
              />
              <span v-if="item.ischeck">文本</span>
              <img
                v-if="item.ischend"
                class="icon_img"
                src="../../../assets/image/video1.png"
                alt=""
              />
              <span v-if="item.ischend">视频</span>
              <img
                v-if="item.ischends"
                class="icon_img"
                src="../../../assets/image/zhp.png"
                alt=""
              />
              <span v-if="item.ischends">照片</span>
              <img
                v-if="item.ischendss"
                class="icon_img"
                src="../../../assets/image/fj.png"
                alt=""
              />
              <span v-if="item.ischendss">附件</span>
            </div>
            <div class="row_flex">
              <!-- <span class="enterprise_txt">{{ item.briefIntroduction }}</span> -->
              <span class="enterprise_txt">发送时间：{{ item.sendTime }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="card_right_left" v-if="!item.isDraft">
            <div class="look_shu" @click="dialogClick(0, item.groupCnt, '总发送群', item.id)">
              <div class="color_num">
                <span>{{ item.groupCnt }}</span>
              </div>
              <div class="name_size">
                <span>总发送群</span>
              </div>
            </div>

            <div class="border_left"></div>
            <div class="look_shu" @click="dialogClick(1, item.groupCntSend, '已发送群', item.id)">
              <div class="color_num">
                <span>{{ item.groupCntSend }}</span>
              </div>
              <div class="name_size">
                <span>已发送群</span>
              </div>
            </div>
            <el-progress
              define-back-color="#8db7fd"
              :width="50"
              color="#448aff"
              type="circle"
              :percentage="item.groupCntSendPercent"
            ></el-progress>
            <div class="look_shu" @click="dialogClick(2, item.groupCntNoSend, '未发送群', item.id)">
              <div class="color_num1">
                <span>{{ item.groupCntNoSend }}</span>
              </div>
              <div class="name_size">
                <span>未发送群</span>
              </div>
            </div>
          </div>
          <img
            v-if="item.type != '节日祝福' && !item.isDraft"
            class="center_zhuanhua"
            src="../../../assets/image/lj1.png"
            alt=""
          />
          <div class="card_right_right" v-if="item.type != '节日祝福' && !item.isDraft">
            <div class="look_shu" @click="dialogClick(3, item.groupCntView, '查看群聊数', item.id)">
              <div class="color_num">
                <span>{{ item.groupCntView }}</span>
              </div>
              <div class="name_size">
                <span>群查看</span>
              </div>
            </div>
            <div class="border_left"></div>
            <div class="look_shu" @click="checkClick(item)">
              <div class="color_num">
                <span>{{ item.userCntView }}</span>
              </div>
              <div class="name_size">
                <span>用户查看</span>
              </div>
            </div>
            <div class="border_left"></div>
            <div class="look_shu" @click="dialogClick(4, item.groupCntNoView, '未查看群', item.id)">
              <div class="color_num1">
                <span>{{ item.groupCntNoView }}</span>
              </div>
              <div class="name_size">
                <span>未查看群</span>
              </div>
            </div>
            <div class="look_shu">
              <div class="color_num2">
                <span>{{ item.groupCntViewPercent }}%</span>
              </div>
              <div class="name_size">
                <span>群查看率</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card_right_but">
          <div class="but_top">
            <div v-if="!item.isDraft">
              <div
                class="down_q"
                v-if="item.runType == 0 || item.runType == 2"
                @click="kaistart(item)"
              >
                <span>开启指令</span>
              </div>
              <div class="down_q" v-if="item.runType == 1" @click="dxstop(item)">
                <span>暂停指令</span>
              </div>
              <div class="down_h" v-if="item.runType == 3">
                <span>已结束</span>
              </div>
            </div>
            <div
              class="down_q"
              v-if="item.runType == 0 || item.runType == 2"
              @click="toEnterpriseDetails(item)"
            >
              <span>编辑</span>
            </div>
            <div class="down_h" v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="由于群发任务进行中，编辑功能无法操作。"
                placement="top-start"
              >
                <el-button style="color: #999999" type="text">编辑</el-button>
              </el-tooltip>
              <!-- <span>编辑</span> -->
            </div>
            <div class="down_q" @click="dxcopy(item.id)">
              <span>复制</span>
            </div>
            <div class="del_t" @click="deleteBtns(item.id)">
              <span>删除</span>
            </div>
          </div>
          <div>
            <span>操作人：{{ item.updateBy }}</span>
          </div>
          <div>
            <span>最近操作时间：{{ item.updateTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="info.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="info.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
    
    <script>
import { listPage, deletePage, runstart, dxstop, dxcopy } from '@/api/masssend.js'

export default {
  name: 'tabitem',
  components: {},
  props: {
    info: {
      type: Object
    }
  },
  data() {
    return {
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      sortVals: 'up', // 排序点击事件的传参
      isActives: '', // 排序图标样式
      checkAll: false,
      loading: false,
      isIndeterminate: false,
      ids: [],
      timelist: [],
      list: [], // 企业列表
      total: 0 // 企业总条数
    }
  },
  created() {
    this.search()
  },
  watch: {
    // 监听 user.name 的变化
    'info.type'(newValue, oldValue) {
      this.search()
    },
    'info.sendStartTime'(newValue, oldValue) {
      this.search()
    },
    'info.sendEndTime'(newValue, oldValue) {
      this.search()
    },
    'info.contentTypes'(newValue, oldValue) {
      this.search()
    }
  },
  computed: {},
  methods: {
    dialogClick(type, index, val, id) {
      let topName = val + '（' + index + '）'
      this.$emit('dialogClick', type, topName, id)
    },
    checkClick(val) {
      this.$emit('checkClick', val.id)
    },
    stripTags(html) {
      const tmp = document.createElement('div')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    timestampToDateTime(timestamp) {
      const date = new Date(timestamp)

      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    async search() {
      this.loading = true

      const res = await listPage(this.info)

      if (res.resultCode == 200) {
        this.list = res.data.list
        this.list.forEach((el, index) => {
          el.groupCntSendPercent = Number(el.groupCntSendPercent)
          el.groupCntViewPercent = Number(el.groupCntViewPercent)
          el.updateTime=this.timestampToDateTime(el.updateTime)
          // if (this.stripTags(el.context)) {
          //   el.context = this.stripTags(el.context)
          // } else {
          //   const srcRegex = /<img[^>]+src="([^">]+)"/
          //    const srcRegex1 = /<video[^>]+src="([^">]+)"/
          //   const match =  el.context.match(srcRegex)

          //   if(match){
          //     el.context=match[1]
          //   }else{
          //     const match1 =  el.context.match(srcRegex1)
          //     if(match1){
          //       el.context=match1[1]
          //     }
          //   }

          // }

          if (el.contentTypes) {
            el.contentTypes.forEach((o) => {
              if (o) {
                if (o == '文本') {
                  this.$set(el, 'ischeck', true)
                } else if (o == '视频') {
                  this.$set(el, 'ischend', true)
                } else if (o == '照片') {
                  this.$set(el, 'ischends', true)
                } else if (o == '附件') {
                  this.$set(el, 'ischendss', true)
                }
              }
            })
          }
        })
        this.total = res.data.total
        this.$emit('totalClick', this.total)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    deleteBtns(id) {
      this.$confirm('确认是否删除，删除后不可撤回')
        .then(async () => {
          const res = await deletePage({ id })
          if (res.resultCode == 200) {
            this.$message.success('删除成功!')
            this.search()
          } else {
            this.$message.warning('删除失败!')
          }
        })
        .catch(() => {})
    },
    dxcopy(id) {
      this.$confirm('确认是否进行复制？')
        .then(async () => {
          const res = await dxcopy({ id })
          if (res.resultCode == 200) {
            this.$message.success('复制成功!')
            this.search()
          } else {
            this.$message.warning('复制失败!')
          }
        })
        .catch(() => {})
    },
    kaistart(item) {
      this.$confirm('确认是否开启指令！')
        .then(async () => {
          const res = await runstart({ id: item.id })
          if (res.resultCode == 200) {
            this.$message.success('开启成功!')
            this.search()
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch(() => {})
    },
    dxstop(item) {
      this.$confirm('确认是否暂停指令！')
        .then(async () => {
          const res = await dxstop({ id: item.id })
          if (res.resultCode == 200) {
            this.$message.success('暂停成功!')
            this.search()
          } else {
            this.$message.warning('暂停失败!')
          }
        })
        .catch(() => {})
    },

    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.list.forEach((item) => {
          //orgCompanyId
          this.ids.push(item.informationId)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.list.forEach((item) => {
          this.ids = []
          this.checkAll = false
          this.$set(item, 'checked1', false)
        })
      }

      this.isIndeterminate = false
    },
    // 多选框选中数据
    latechange(bol, row) {
      // orgCompanyId
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        //orgCompanyId
        this.ids.push(row.id)
      } else {
        this.ids.splice(index, 1)
      }
      if (bol) {
        if (this.ids.length === this.list.length) {
          this.isIndeterminate = false
          this.checkAll = true
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      } else {
        if (this.ids.length < 1) {
          this.isIndeterminate = false
          this.checkAll = false
        } else {
          this.isIndeterminate = true
          this.checkAll = false
        }
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.info.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.info.pageNum = newCurrent
      this.search()
    },

    toEnterpriseDetails(item) {
      if(item.runType==0||item.runType==2){
        this.$router.push({
        name: 'CreateContent',
        query: {
          id: item.id
        }
      })
      }
    
    }
  }
}
</script>
    
<style lang="less" scoped>
.customer_manager {
  min-height: calc(100vh - 600px);
  overflow: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
::v-deep .el-progress__text {
  font-size: 12px !important; /* 使用 !important 来确保样式优先级 */
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #333333;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 0;
  top: -1px;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 0.0625rem solid #fff !important;
  border-radius: 0.25rem 0 0 0.25rem;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
video::-webkit-media-controls {
  display: none !important;
}

.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
.search_right {
  width: 460px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.check {
  margin-left: auto;
  margin-right: 30px;
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-button {
    margin-right: 10px;
  }
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.icon_box {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  img {
    width: 16px;
    height: 16px;
  }
  i {
    font-size: 16px;
  }
}
.el-radio-button ::v-deep .el-radio-button__inner {
  border-radius: 4px !important;
  border: 0 !important;
}
.row_txt {
  font-size: 16px;
}
.sort {
  display: flex;
  align-items: center;
  margin-left: 20px;
  span {
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    margin-right: 5px;
  }
  .sort_icon {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    height: auto;
    i {
      width: 14px;
      height: 14px;
      color: #dbd6d6;
      font-size: 14px;
    }
    .isActive {
      color: #8a8888 !important;
    }
  }
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: calc(100vh - 650px);
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px 20px 10px 0px;
    box-sizing: border-box;
    position: relative;
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      width: 30%;
      margin-right: 20px;

      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .img_box {
        width: 164px;
        height: 88px;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        .videoUrl {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); /* 垂直和水平居中 */
          img {
            width: 30px;
            height: 30px;
          }
        }
        img {
          width: 164px;
          height: 88px;
        }
        video {
          width: 164px;
          height: 88px;
        }
        .po_left {
          position: absolute;
          min-width: 64px;
          height: 23px;
          text-align: center;
          line-height: 23px;
          left: 0px;
          top: 0px;
          border-radius: 2px;
          color: #fff;
          font-size: 14px;
        }
        .bg1 {
          background: #36d423;
        }
        .bg2 {
          background: #448aff;
        }
        .bg3 {
          background: #f1406f;
        }
        .bg4 {
          background: #ff7d18;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .row_margin {
          margin: 15px 0px;
          display: flex;
          align-items: center;
          font-size: 14px;
          .icon_img {
            width: 16px;
            height: 16px;
            margin: 0px 5px;
          }

          .clueType_right {
            margin-left: 10px;
            max-width: 340px; /* 固定宽度超出隐藏 */
            overflow: hidden; /*  超出隐藏 */
            text-overflow: ellipsis; /*  超出以 ...显示 */
            display: -webkit-box;
            -webkit-line-clamp: 1; /* // 显示1行 */
            -webkit-box-orient: vertical;
          }
        }
        .row_flex {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        .enterprise_txt {
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 300px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name:hover {
          color: #448aff;
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      align-items: center;
      .card_right_left {
        display: flex;
        align-items: center;
        background: #fafafa;
        height: 88px;
        width: 400px;
        border-radius: 4px;
        .border_left {
          height: 57px;
          width: 1px;
          background: #e8e8e8;
        }
        .look_shu {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 0px 20px;
          cursor: pointer;
          .color_num {
            font-size: 20px;
            color: #448aff;
          }
          .color_num1 {
            font-size: 20px;
            color: #ff5555;
          }

          .name_size {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
      .center_zhuanhua {
        width: 32px;
        height: 30px;
        margin: 0px 15px;
      }
      .card_right_right {
        display: flex;
        align-items: center;
        background: #fafafa;
        height: 88px;
        width: 440px;
        border-radius: 4px;
        .border_left {
          height: 57px;
          width: 1px;
          background: #e8e8e8;
        }
        .look_shu {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 0px 20px;
          cursor: pointer;
          .color_num {
            font-size: 20px;
            color: #448aff;
          }
          .color_num1 {
            font-size: 20px;
            color: #ff5555;
          }
          .color_num2 {
            font-size: 20px;
            color: #ff7d18;
          }
          .name_size {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
    }
    .card_right_but {
      min-width: 200px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: 20px;
      color: #999999;
      font-size: 12px;
      .but_top {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        .down_q {
          color: #448aff;
          margin-left: 10px;
        }
        .down_h {
          color: #999999;
          margin-left: 10px;
        }
        .del_t {
          color: #f53939;
          margin-left: 10px;
        }
      }
    }
  }
}

.color1 {
  color: #4e93fb;
  font-size: 16px;
}
.color2 {
  color: #fd5469;
  font-size: 16px;
}
.color3 {
  color: #fc8b39;
  font-size: 16px;
}
</style>
    