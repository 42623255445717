<template>
  <div class="dialogtable">
    <el-dialog
      :visible.sync="addDialogVisible"
      :before-close="addDialogClosed"
      :close-on-click-modal="false"
      top="100px"
      width="60%"
    >
      <template slot="title">
        <div class="custom_title">
          <div class="border_left"></div>
          <span>用户查看{{ (total||'') }}</span>
        </div>
      </template>
      <el-table
        :data="allList"
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="头像" align="center" prop="head">
          <template v-slot="{ row }">
            <img class="crile_size" v-if="!row.head" src="../../../assets/image/clue-weixinzhifu.png" alt="" />
            <img class="crile_size" v-else :src="row.head" alt="" />
          </template>
        </el-table-column>

        <el-table-column label="昵称" prop="nickName" align="center">
          <template v-slot="{ row }">
            <div>{{ row.nick }}</div>
          </template>
        </el-table-column>
        <el-table-column label="性别" prop="sex" align="center">
          <template v-slot="{ row }">
            <div v-if="row.sex">男</div>
            <div v-else>女</div>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="phone" align="center">
          <template v-slot="{ row }">
            <div>{{ row.phone }}</div>
          </template>
        </el-table-column>
        <el-table-column label="所在城市" prop="city" align="center">
          <template v-slot="{ row }">
            <!-- <span>{{ row.province }}</span> -->
            <span v-if="row.city">{{ row.city }}</span>
          
          </template>
        </el-table-column>
        <el-table-column label="查看次数" prop="viewCnt" align="center">
          <template v-slot="{ row }">
            <div>{{ row.viewCnt }}</div>
          </template>
        </el-table-column>
        <el-table-column label="查看时间" prop="viewTime" align="center">
          <template v-slot="{ row }">
            <div>{{ row.viewTime }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
  </div>
</template>
    
    <script>
import { userview } from '@/api/masssend.js'
const defaultQueryInfo = Object.freeze({
  dsMsgId: '',
  orderByParam: 0,
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'dialogtable',

  props: {
    activeIds: {
      type: String
    }
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      allList: [],
      total: 0,

      addDialogVisible: false
    }
  },
  watch: {
    activeIds(newValue){
    
    }
  },
  created() {
  
  },
  computed: {},
  methods: {
    async userview() {
      
      this.queryInfo.dsMsgId=this.activeIds
      const res = await userview( this.queryInfo)
      if(res.resultCode==200){
        console.log(res);
        
        this.allList=res.data.list
        this.total=res.data.total
        console.log(this.total);
        
      }
    },
    addDialogClosed() {
      this.addDialogVisible = false
      this.id = null
      this.$refs.child.clear()
      this.form = { ...defaultForm }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>
    <style lang="less" scoped>
.dialogtable {
  .custom_title {
    position: relative;
    font-size: 16px;
    .border_left {
      position: absolute;
      height: 16px;
      width: 4px;
      background: #448aff;
      left: -20px;
    }
  }
  .crile_size {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
</style>
    