<template>
  <div class="dialogtable">
    <el-dialog
      :visible.sync="addDialogVisible"
      :before-close="addDialogClosed"
      :close-on-click-modal="false"
      top="100px"
      width="50%"
    >
      <template slot="title">
        <div class="custom_title">
          <div class="border_left"></div>
          <span>{{ activeName }}</span>
        </div>
      </template>
      <el-table
        :data="allList"
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="群聊名称" align="center" prop="id">
          <template v-slot="{ row }">
            <div>{{ row.wechatGroupName }}</div>
          </template>
        </el-table-column>

        <el-table-column label="成员数量" prop="imgName" align="center">
          <template v-slot="{ row }">
            <div>{{ row.memCnt }}</div>
          </template>
        </el-table-column>

        <el-table-column v-if="activeType == 1" label="发送时间" prop="link" align="center">
          <template v-slot="{ row }">
            <span>{{ row.sendTime }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="activeType == 3" label="查看时间" prop="link" align="center">
          <template v-slot="{ row }">
            <span>{{ row.viewTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="activeType != 3 && activeType != 1"
          label="最近消息时间"
          prop="link"
          align="center"
        >
          <template v-slot="{ row }">
            <span>{{ row.sendTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { wechatGroupList } from '@/api/masssend.js'
const defaultQueryInfo = Object.freeze({
  dsMsgId: '',
  orderByParam: 0,
  pageNum: 1,
  pageSize: 10,
  type: 0
})
export default {
  name: 'dialogtable',

  props: {
    activeName: {
      // 0是新增，1是编辑
      type: String
    },
    activeType: {
      type: Number
    },
    activeId: {
      type: String
    }
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      allList: [],
      total: 0,

      addDialogVisible: false
    }
  },
  watch: {
    activeId(val) {
      // this.wechatGroupList()
    }
  },
  created() {},
  computed: {},
  methods: {
    async wechatGroupList() {
      this.queryInfo.dsMsgId = this.activeId
      this.queryInfo.type=this.activeType
      const res = await wechatGroupList(this.queryInfo)
      if (res.resultCode == 200) {
        this.allList = res.data.list
        this.total = res.data.total
      }
    },
    async userview() {
      
      this.queryInfo.dsMsgId=this.activeIds
      const res = await userview( this.queryInfo)
      if(res.resultCode==200){
        this.allList=res.data.list
        this.total=res.data.total
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>
  <style lang="less" scoped>
.dialogtable {
  .custom_title {
    position: relative;
    font-size: 16px;
    .border_left {
      position: absolute;
      height: 16px;
      width: 4px;
      background: #448aff;
      left: -20px;
    }
  }
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}
</style>
  